<!--<div class="row background-grey">
<div class="mt-2 ml-0 mr-0 pl-0 pr-0 col-12 col-md-6 col-lg">

</div>
</div>-->

<div class="row">
  <mat-form-field class="mt-3 mb-0 col-12 col-md-8 col-lg-3">
    <input type="search" matInput i18n-placeholder placeholder="Search" name="searchFilter" id="searchFilter" [(ngModel)]="search_filter" (keyup)="filter_datasource()" />
    <button mat-button *ngIf="search_filter" matSuffix mat-icon-button (click)="clearFilter_datasource()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field *ngIf="exchangeName==='chainex'" class=" mt-3 mb-0 col-5 col-md-4 col-lg-2">
    <mat-select i18n-placeholder placeholder="Currency Valuation" (selectionChange)="estimated_changed()" [(ngModel)]="estimated_selected">
      <mat-option *ngFor="let estimated of estimated_currencies" [value]="estimated">
        {{estimated}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!--<mat-form-field class="mt-3 col-6 col-md-4 col-lg-2">
    <mat-select placeholder="Display Balances" [(ngModel)]="display_selected">
      <mat-option *ngFor="let balance of display_balances" [value]="balance">
        {{balance}}
      </mat-option>
    </mat-select>
  </mat-form-field>-->

  <div class="mt-3 mb-0 col-6 col-md-6 col-lg-3 col-xlg-2 center-checkbox">
      <mat-checkbox  [(ngModel)]="showZeroBalances" (change)="toggle_zero()" i18n>Hide Zero Balances</mat-checkbox>
  </div>
  <div class="mt-2 col-12 col-md-6 col-lg center-checkbox">
      <app-coin-balance></app-coin-balance>
  </div>

</div>

<div class="table-container full-table">
  <table mat-table matSort [dataSource]="balance_data" class="w-100" [trackBy]="trackByCoin">

    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="coin-col" i18n>Coin</th>
        <td mat-cell *matCellDef="let element" class="coin-col"> {{element.code}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="balance_available">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Available</th>
      <td mat-cell *matCellDef="let element"> {{element.balance_available }} </td>
    </ng-container>

    <ng-container matColumnDef="balance_pending_deposit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Deposit</th>
      <td mat-cell *matCellDef="let element"> {{element.balance_pending_deposit }} </td>
    </ng-container>

    <ng-container matColumnDef="balance_pending_withdraw">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Withdrawal</th>
      <td mat-cell *matCellDef="let element"> {{element.balance_pending_withdraw }} </td>
    </ng-container>

    <ng-container matColumnDef="balance_held">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Held orders</th>
      <td mat-cell *matCellDef="let element"> {{element.balance_held }} </td>
    </ng-container>

    <ng-container matColumnDef="earn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Earn</th>
      <td mat-cell *matCellDef="let element"> {{(element.code !== 'ZAR' && element.has_product ? (element.earn ) : 'NA')}} </td>
    </ng-container>

    <ng-container matColumnDef="balance_total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Total</th>
      <td mat-cell *matCellDef="let element"> {{element.balance_total }} </td>
    </ng-container>

    <ng-container matColumnDef="btc_estimated_value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Est. (BTC)</th>
      <td mat-cell *matCellDef="let element"> {{element.btc_estimated_value }} </td>
    </ng-container>

    <ng-container matColumnDef="estimated_value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Est. ({{estimated_selected}})</th>
      <td mat-cell *matCellDef="let element"> {{ (estimated_selected === 'ZAR' ? element.zar_estimated_value : element.usdt_estimated_value)}} </td>
    </ng-container>

    <ng-container matColumnDef="change">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>% Change</th>
      <td mat-cell *matCellDef="let element"> {{element.change==='NA'?element.change:element.change+'%'}} </td>
    </ng-container>

    <ng-container matColumnDef="action" stickyEnd >
      <th mat-header-cell *matHeaderCellDef i18n>Action</th>
      <td mat-cell *matCellDef="let element">
        <div class="button-container">
          <div class="w-22 mr-1">
            <mat-icon *ngIf="exchangeName==='chainex'" (click)="deposit_click(element)" color="primary" svgIcon="deposit" i18n-matTooltip matTooltip="{{element.code}} {{translatedTooltip[0]}}" class="icon-size-limit"></mat-icon>
            <mat-icon *ngIf="exchangeName==='burnx'" (click)="deposit_click(element)" color="primary" matTooltip="{{element.code}} {{translatedTooltip[0]}}" class="icon-size-limit">add</mat-icon>
          </div>

          <div class="w-22 mr-1">
            <mat-icon *ngIf="exchangeName==='chainex' && !(element.disabled || withdrawalsDisabled); else withdrawIconDisabled"
              (click)="withdraw_click(element)" color="primary" svgIcon="withdraw" i18n-matTooltip matTooltip="{{element.code}} {{translatedTooltip[1]}}"></mat-icon>
            <ng-template #withdrawIconDisabled>
              <mat-icon color="disabled" svgIcon="withdraw" i18n-matTooltip matTooltip="{{element.code}} Withdrawals Disabled" disabled>
              </mat-icon>
            </ng-template>
            <mat-icon *ngIf="exchangeName==='burnx'" (click)="withdraw_click(element)" color="primary" matTooltip="{{element.code}} {{translatedTooltip[1]}}">remove</mat-icon>
          </div>

          <div class="w-22 mr-1">
            <mat-icon (click)="changes_click(element)" color="primary" svgIcon="market" i18n-matTooltip matTooltip="{{element.code}} {{translatedTooltip[2]}}" class="d-md-inline-block"></mat-icon>
          </div>

          <div class="w-22 mr-1">
            <mat-icon (click)="history_click(element)" color="primary" svgIcon="history" data-container="body" i18n-matTooltip matTooltip="{{element.code}} {{translatedTooltip[3]}}"></mat-icon>
          </div>

          <div class="w-30" *ngIf="showEarn">
            <ng-container *ngIf="has_product(element); else noProduct">
              <mat-icon (click)="earn_click(element)" color="primary" svgIcon="piggy-banking" data-container="body" i18n-matTooltip matTooltip="{{element.code}} {{translatedTooltip[4]}}" class="w-20"></mat-icon>
            </ng-container>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="get_columns()"></tr>
    <tr mat-row *matRowDef="let row; columns: get_columns();"></tr>

  </table>
</div>
<br>
<div class="w-100 text-center" *ngIf="isLoading" i18n>Loading balances..</div>
<div class="w-100 text-center" *ngIf="!isLoading && (!balance_data || balance_data.filteredData.length === 0)" i18n>No records found</div>
<mat-paginator i18n-itemsPerPageLabel itemsPerPageLabel="Items per page:" [length]="total_records" [pageSize]="list_number" [pageSizeOptions]="page_sizes" (page)="paging_change($event)" class=""></mat-paginator>
